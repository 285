import React, { useEffect, useMemo } from 'react';

import meetup_event_og from '@images/meetup-event/meetup_event_og.png';
import PageLayout from '@components/common/Layout/PageLayout';
import { useReferQueryParams } from '@hooks/useReferQueryParams';
import { appInfo, initAppInfo } from '@utils/bridge/appInfo';
import { trackEvent } from '@utils/analytics';
import { Metadata } from '@components/common/Metadata';
import { BrdigeRegionType } from '@utils/bridge';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import ShareButtonWrapper from '@components/common/ShareButtonWrapper';
import { COMMUNITY_LINK_DOMAIN_URL, openGroupHomePage } from '@utils/link';
import BottomFloatingButton from '@components/common/BottomFloatingButton';
import qs from 'query-string';
import { bridge } from '@utils/bridge/index';

const MEETUP_EVENT_URL = '/meetup-event';

const EVENT_NAME = 'meetup_event';

/*
 *
 * 이벤트 진행 중인 지역
 *
 */
const MeetupEvent = ({ uri }: any) => {
  const referParams = useReferQueryParams();
  const copyShareUrlRef = React.useRef<boolean>(false);
  const [userRegion, setUserRegion] = React.useState<BrdigeRegionType>();

  const handleSetUserInfo = async () => {
    const userRegionInfo = appInfo.getData()?.region;
    setUserRegion(userRegionInfo as BrdigeRegionType);
  };

  useEffect(() => {
    window.location.href = `${COMMUNITY_LINK_DOMAIN_URL}/`;
    // handleSetUserInfo();
  }, []);

  const copyText = useMemo(() => {
    let path = `당근 모임 일정 이벤트🎉\n${COMMUNITY_LINK_DOMAIN_URL}${MEETUP_EVENT_URL}`;
    return path;
  }, []);

  // 이벤트 지역인지 & 동네인증 여부 & 사전 신청 여부를 확인 & 사전 신청 진행
  const applyHandler = async () => {
    await initAppInfo();
    const authToken = appInfo.getData()?.user.authToken;
    if (!authToken) return;

    const referParamsString = qs.stringify({
      ...referParams,
      from: 'firstWebview',
      activeHomeTab: 'meetupTab',
    });
    trackEvent(`click_explorer_group_button-${EVENT_NAME}`, {
      ...referParams,
      page_name: 'group_meetup_event_landing',
      userId: appInfo.getData()?.user.id,
    });

    if (referParams?.callbackAction === 'pop') {
      bridge?.closeRouter({});
      bridge?.emitToStream({
        stream: {
          eventName: 'openMeetupTabInHome',
        },
      });
      return;
    }

    openGroupHomePage(referParamsString ? `?${referParamsString}` : '');
  };

  // 앱 내 페이지로 이동시킴
  const logMoveToApp = () => {
    trackEvent(`click_move_to_app-${EVENT_NAME}`, {
      ...referParams,
      page_name: 'meetup_event_landing',
    });
  };

  return (
    <PageLayout
      enterEventName={`enter_landing_page-${EVENT_NAME}`}
      eventParams={{
        ...referParams,
        page_name: 'group_meetup_event_landing',
        regionId: appInfo.getData()?.region.id,
        region2Id: appInfo.getData()?.region?.name2Id,
        regionName: appInfo.getData()?.region?.name,
        inviteHashId: (referParams?.invite as string) ?? '',
      }}
    >
      <ImageWrapper>
        <StaticImage
          src={'../../assets/meetup-event/meetup_event_header.png'}
          alt="meetup_event_contents"
          placeholder="blurred"
        />
      </ImageWrapper>
      <ImageWrapper>
        <StaticImage
          src={'../../assets/meetup-event/meetup_event_contents.png'}
          alt="meetup_event_contents"
          placeholder="blurred"
        />
      </ImageWrapper>
      <ShareButtonWrapper
        copyText={copyText}
        onClickShare={() => {
          // 페이지당 여러차례 클릭 이벤트 로깅이 될 수 있기 때문에 ref를 사용하여 1회만 로깅되도록 함
          if (!copyShareUrlRef.current) {
            trackEvent(`click_share_event_url-${EVENT_NAME}`, {
              page_name: 'group_meetup_event_landing',
              userId: appInfo.getData()?.user?.id,
              region2Id: appInfo.getData()?.region.name2Id,
              regionId: appInfo.getData()?.region.id,
              regionName: appInfo.getData()?.region.name,
            });
            copyShareUrlRef.current = true;
          }
        }}
      >
        <StaticImage
          src={'../../assets/meetup-event/Share.png'}
          alt="share"
          placeholder="blurred"
        />
      </ShareButtonWrapper>
      <StaticImage
        src={'../../assets/meetup-event/meetup_event_footer.png'}
        alt="footer"
        placeholder="blurred"
      />
      <BottomFloatingButton
        hideButton={referParams?.hideButton === 'true'}
        onClickKarrotWebview={applyHandler}
        karrotWebviewButtonText={'일정 둘러보기'}
        onClickNotKarrotWebview={logMoveToApp}
        openAppPath={MEETUP_EVENT_URL}
      />
    </PageLayout>
  );
};

export default MeetupEvent;

export const Head = () => {
  return (
    <Metadata title={'당근 모임 일정 이벤트'} image={meetup_event_og} pathname={MEETUP_EVENT_URL} />
  );
};

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
